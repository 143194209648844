.dropzone {
  border: 1px dashed #ccc;
  border-radius: 4px;
  padding: 0;
  text-align: left;
  background-color: #f9f9f9;
  cursor: pointer;
  transition: border-color 0.3s ease;
  position: relative;
  
  &:hover {
    border-color: #0d6efd;
  }
  
  &.dragActive {
    border-color: #0d6efd;
    background-color: rgba(13, 110, 253, 0.05);
  }
  
  &.disabled {
    cursor: not-allowed;
    opacity: 0.7;
    
    &:hover {
      border-color: #ccc;
    }
  }
}

.dropzoneContent {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  padding: 24px;
}

.dropzoneText {
  font-size: 16px;
  font-weight: 500;
  margin-bottom: 8px;
  color: #212529;
}

.dropzoneSubtext {
  font-size: 14px;
  color: #6c757d;
  margin-bottom: 0;
}

.selectFileButton {
  position: absolute;
  right: 16px;
  top: 50%;
  transform: translateY(-50%);
  background-color: white;
  border: 1px solid #dee2e6;
  border-radius: 4px;
  padding: 8px 20px;
  font-size: 14px;
  cursor: pointer;
  transition: background-color 0.2s ease;
  
  &:hover {
    background-color: #f8f9fa;
  }
}

.fileList {
  margin-top: 16px;
}

.fileItem {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 12px 16px;
  margin-bottom: 8px;
  border: 1px solid #dee2e6;
  border-radius: 4px;
  background-color: #fff;
}

.fileInfo {
  display: flex;
  align-items: center;
  overflow: hidden;
  flex: 1;
}

.fileIconContainer {
  width: 32px;
  height: 32px;
  background-color: #f1f1f1;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 12px;
  flex-shrink: 0;
}

.fileIcon {
  color: #6c757d;
  flex-shrink: 0;
  transform: rotate(0);
  width: 16px;
  height: 16px;
}

.fileDetails {
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

.fileName {
  font-size: 14px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  color: #0d6efd;
  font-weight: 500;
}

.fileSize {
  font-size: 12px;
  color: #6c757d;
  white-space: nowrap;
}

.deleteButton {
  display: flex;
  align-items: center;
  justify-content: center;
  background: none;
  border: none;
  color: #6c757d;
  cursor: pointer;
  padding: 4px;
  border-radius: 4px;
  transition: color 0.2s ease;
  margin-left: 8px;
  
  &:hover {
    color: #dc3545;
  }
  
  svg {
    width: 20px;
    height: 20px;
  }
}

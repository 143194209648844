@mixin base {
  cursor: pointer;
  padding: 2px;
  height: 44px;
  border: 1px solid #f3f3f3;
  border-radius: 8px;
  display: flex;
  align-items: center;
}

.dropzone {
  @include base;
}

.delete {
  @include base;
  justify-content: space-between;
}

.fileList {
  margin-top: 10px;
}

.fileItem {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 8px 12px;
  margin-bottom: 8px;
  border: 1px solid #f3f3f3;
  border-radius: 8px;
  background-color: #fff;
}

.fileName {
  flex: 1;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  font-size: 14px;
}

.deleteButton {
  display: flex;
  align-items: center;
  background: none;
  border: none;
  color: #6c757d;
  font-size: 13px;
  cursor: pointer;
  padding: 5px 10px;
  border-radius: 4px;
  
  &:hover {
    background-color: #f8f9fa;
  }
  
  svg {
    margin-right: 5px;
    width: 16px;
    height: 16px;
  }
}

.btn {
  height: 40px;
  padding: 4px 16px;
  background: #f9f9f9;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.label {
  background-color: #f9f9f9;
  border-radius: 8px;
  border: 1px solid #fff;
  color: #121212;
  font-size: 13px;
  font-weight: 500;
  padding: 0.5rem;
  cursor: pointer;
  width: 100%;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  outline: 1px solid #f3f3f3;
}

.input {
  display: none;
}

.attachIcon {
  margin-right: 0.5rem;
  transform: rotate(45deg);
}

.addMoreFilesContainer {
  margin-top: 10px;
}

.addMoreLabel {
  display: flex;
  align-items: center;
  color: #0d6efd;
  font-size: 13px;
  cursor: pointer;
  padding: 5px 0;
  
  &:hover {
    text-decoration: underline;
  }
}
